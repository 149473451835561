import React from "react"
import { Link } from "gatsby"

export default () => (
  <footer>
    <nav bp="container grid 12 4@sm text-center" className="main-navigation">
      <Link to="/workshops/">Workshops</Link>
      <Link to="/about/">Who we are</Link>
      <a
        href="https://stateofprogress.blog"
        title="State of Progress"
        target="_blank"
        rel="noopener noreferrer"
      >
        Our blog
      </a>
    </nav>
    <aside>© {new Date().getFullYear()} SourceLair Private Company</aside>
  </footer>
)
