import React from "react"
import { Link } from "gatsby"

export default () => (
  <>
    <header className="brand">
      <Link to="/">2hog</Link>
    </header>
    <div className="header-banner">
      <span role="img" aria-label="Heads up!">
        🙌
      </span>
      <strong>Hey! We got news for you.</strong> We released a new online class
      on <strong>Introduction to Docker</strong>, with all videos FREE on
      YouTube!{" "}
      <a
        href="https://www.youtube.com/watch?v=_efskzo-bQU&list=PL-bAoD8eSFfHtI0Lknro_TbXSGGbXOEpZ"
        target="_blank"
        rel="noopener noreferrer"
      >
        Check it out
      </a>
      .
    </div>
  </>
)
