import moment from "moment"

const getTicketByName = (tickets, name) => {
  const filteredTickets = tickets.filter(ticket => ticket.name === name)
  return filteredTickets.length ? filteredTickets[0] : null
}

export const getGeneralAvailabilityTicket = tickets =>
  getTicketByName(tickets, "General Availability")

export const getEarlyBirdTicket = tickets => {
  const ticket = getTicketByName(tickets, "Early Bird")
  const earlyBirdDateHasPassed = ticket
    ? moment(ticket.to).isBefore(new Date())
    : false

  if (earlyBirdDateHasPassed) {
    return null
  }

  return ticket
}

export const getAvailability = tickets => {
  const ticket = getGeneralAvailabilityTicket(tickets)
  return ticket.availability.toLowerCase()
}

export const getJsonLd = workshop => ({
  "@context": "https://schema.org",
  "@type": "Event",
  name: workshop.title,
  startDate: workshop.start,
  endDate: workshop.end,
  location: {
    "@type": "Place",
    name: workshop.venue_name,
    address: {
      "@type": "PostalAddress",
      streetAddress: "Plateia Agion Theodoron 6",
      addressLocality: "Athens",
      postalCode: "10561",
      addressRegion: "Attica",
      addressCountry: "GR",
    },
  },
  image: [workshop.image],
  description: workshop.excerpt,
  offers: [
    workshop.tickets.map(ticket => ({
      "@type": "Offer",
      url: `https://2hog.codes${workshop.path}`,
      price: ticket.price,
      priceCurrency: "EUR",
      availability: `https://schema.org/${ticket.availability}`,
      validFrom: ticket.from,
      validThrough: ticket.to,
    })),
  ],
  performer: {
    "@type": "Person",
    name: workshop.instructor_name,
    url: workshop.instructor_url,
  },
})

export const getMeta = workshop => [
  {
    property: "og:title",
    content: workshop.title,
  },
  {
    property: "og:description",
    content: workshop.excerpt,
  },
  {
    property: "og:type",
    content: "article",
  },
  {
    property: "og:image",
    content: workshop.image,
  },
  {
    name: "twitter:card",
    content: "summary_large_image",
  },
  {
    name: "twitter:creator",
    content: "sourcelair",
  },
  {
    name: "twitter:title",
    content: workshop.title,
  },
  {
    name: "twitter:description",
    content: workshop.excerpt,
  },
  {
    name: "twitter:image",
    content: workshop.image,
  },
  {
    name: "twitter:image:alt",
    content: workshop.title,
  },
]
