import React from "react"
import { graphql } from "gatsby"

import WorkshopsIndexTemplate from "../../templates/workshops/index"

export const getTags = edges =>
  edges.reduce((acc, item) => {
    if (item.node.frontmatter.published === true) {
      item.node.frontmatter.tags.forEach(tag => {
        if (!acc.includes(tag)) {
          acc.push(tag)
        }
      })
    }

    return acc
  }, [])

export default ({ data }) => {
  const workshops = data.allMarkdownRemark.edges
  const tags = getTags(workshops)
  const pageContext = { workshops, tags }
  return <WorkshopsIndexTemplate pageContext={pageContext} />
}

export const query = graphql`
  query WorkshopsQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            date
            tags
            tickets {
              name
              price
              availability
            }
            availability
            excerpt
            category
            instructor_name
            instructor_url
            venue_name
            venue_url
            image
            image
            eventbrite_link
            published
          }
        }
      }
    }
  }
`
