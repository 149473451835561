import React from "react"
import PropTypes from "prop-types"
import { Link, withPrefix } from "gatsby"
import moment from "moment"
import { getAvailability } from "../utils/workshops"

const WorkshopCard = ({ workshop }) => (
  <Link
    to={workshop.path}
    className={`workshop-card availability-${getAvailability(
      workshop.tickets
    )}`}
  >
    <img
      className="workshop-card-thumbnail"
      src={withPrefix(`/img/workshops/categories/${workshop.category}.svg`)}
      alt="Workshop category"
    />
    <h2 className="workshop-card-title">{workshop.title}</h2>
    <p className="workshop-card-summary">{workshop.excerpt}</p>
    <div className="workshop-card-details">
      <time className="workshop-card-date" dateTime={workshop.date}>
        {moment(workshop.date).format("dddd D MMMM YYYY")}
      </time>
      <div className="workshop-card-instructor">{workshop.instructor_name}</div>
    </div>
  </Link>
)

WorkshopCard.propTypes = {
  workshop: PropTypes.shape({
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    instructor_name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
}

export default WorkshopCard
