import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import isEmail from "validator/lib/isEmail"
import cn from "classnames"

import "../styles/newsletter.scss"

export default class MyGatsbyComponent extends React.Component {
  state = {
    email: "",
    isValid: false,
    isRegistering: false,
    isRegistered: false,
  }

  // 2. via `async/await`
  handleSubmit = async e => {
    try {
      e.preventDefault()

      await this.setState({
        isRegistering: true,
      })

      const { result } = await addToMailchimp(this.state.email)

      await this.setState({
        isRegistering: false,
      })

      if (result === "success") {
        await this.setState({
          email: "",
          isValid: false,
          isRegistered: true,
        })
      } else {
        throw new Error()
      }
    } catch (err) {
      this.setState({
        isValid: false,
        isRegistering: false,
      })
    }
  }

  handleChange = e => {
    const { value } = e.target

    this.setState({
      email: value,
      isValid: isEmail(value),
    })
  }

  render() {
    const { email, isValid, isRegistered, isRegistering } = this.state

    return (
      <div bp="container" className="newsletter-container">
        <div className="newsletter-header">
          <h1 className="newsletter-heading">
            Subscribe to our newsletter to stay up to date
          </h1>
          <p>
            <strong>Just 1 email per month</strong> with new workshop
            announcements.
          </p>
          <p>
            We won't ever spam you with more emails or share your email address
            with anyone.
          </p>
        </div>
        {isRegistered ? (
          <p className="alert alert-info">
            Almost finished! Please click the link in the email we just sent
            you, to confirm your subscription.
          </p>
        ) : (
          <form onSubmit={this.handleSubmit} className="newsletter-form">
            <div className="input-group">
              <input
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                className="input"
              />
              <button
                type="submit"
                disabled={!isValid || isRegistering}
                className={cn("button button-cta", {
                  "is-loading": isRegistering,
                })}
              >
                Subscribe
              </button>
            </div>
            <small>Please enter a valid email address</small>
          </form>
        )}
      </div>
    )
  }
}
