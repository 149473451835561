import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PersonCard from "../components/PersonCard"

export default () => (
  <Layout>
    <Seo title="Who we are" />

    <h1 className="text-display text-center">The people behind 2hog</h1>

    <div bp="grid 3@xl 3@lg 6@md 12@sm" className="people-grid">
      <PersonCard
        name="Paris Kasidiaris"
        url="https://twitter.com/pariskasid"
        image="https://pbs.twimg.com/profile_images/635117805529001984/PmINK9B2_400x400.jpg"
      />
      <PersonCard
        name="Antonis Kalipetis"
        url="https://twitter.com/akalipetis"
        image="https://pbs.twimg.com/profile_images/781618841273131009/Zj7vPkME_400x400.jpg"
      />
      <PersonCard
        name="Marios Fakiolas"
        url="https://twitter.com/fakiolinho"
        image="https://pbs.twimg.com/profile_images/722832952757993472/gmB-IiGc_400x400.jpg"
      />
      <PersonCard
        name="Dimitris Togias"
        url="https://twitter.com/demo9"
        image="https://pbs.twimg.com/profile_images/891328099928899584/oopDR8Hv_400x400.jpg"
      />
    </div>
  </Layout>
)
