import React from "react"
import { graphql } from "gatsby"
import moment from "moment"

import Layout from "components/Layout"
import Seo from "components/Seo"
import WorkshopGrid from "components/WorkshopGrid"
import WorkshopTags from "components/WorkshopTags"

import "../../styles/workshops.scss"

export default ({ data, pageContext }) => {
  const today = new Date()
  const { tag = "All", tags, workshops } = pageContext
  const upcomingWorkshops = workshops.filter(edge => {
    return moment(edge.node.frontmatter.date).isAfter(today)
  })
  const pastWorkshops = workshops.filter(edge => {
    return moment(edge.node.frontmatter.date).isBefore(today)
  })

  return (
    <Layout>
      <Seo
        title={`${tag} workshops`}
        description="Check the latest Docker, Kubernetes and React workshops by 2hog."
      />

      <WorkshopTags tags={tags} activeTag={tag} />

      {upcomingWorkshops.length > 0 && (
        <WorkshopGrid title="Upcoming workshops" edges={upcomingWorkshops} />
      )}

      {pastWorkshops.length > 0 && (
        <WorkshopGrid title="Past workshops" edges={pastWorkshops} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query TaggedWorkshopsQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            availability
            excerpt
            category
            instructor_name
            image
          }
        }
      }
    }
  }
`
