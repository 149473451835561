import React from "react"
import { Link, navigate } from "gatsby"
import Dropdown from "react-dropdown"

import "react-dropdown/style.css"

const all = "All"

const getPathForTag = tag =>
  tag === all ? "/workshops/" : `/workshops/tags/${tag}`

const WorkshopTag = ({ name, active, ...restProps }) => {
  const className = `workshop-tag ${active ? "workshop-tag-active" : ""}`
  const to = getPathForTag(name)

  return (
    <Link className={className} to={to} {...restProps}>
      {name}
    </Link>
  )
}

export default ({ tags, activeTag = all }) => {
  const sortedTags = tags.sort()
  const allAndTags = tags.includes(all) ? sortedTags : [all].concat(sortedTags)

  return (
    <div className="workshop-tags">
      {allAndTags.map(tag => (
        <WorkshopTag
          key={tag}
          name={tag}
          active={tag === activeTag}
          bp="hide show@lg"
        />
      ))}

      <div bp="show hide@lg full-width">
        <Dropdown
          className="dropdown"
          options={allAndTags}
          onChange={option => navigate(getPathForTag(option.value))}
          value={activeTag}
          placeholder="Select an option"
        />
      </div>
    </div>
  )
}
