import React from "react"
import PropTypes from "prop-types"

import Head from "./Head"
import Header from "./Header"
import Footer from "./Footer"
import Newsletter from "./Newsletter"

import "blueprint-css/dist/blueprint.min.css"
import "../styles/layout.scss"

const Layout = ({ hasNewsletter, children }) => (
  <div className="layout">
    <Head />

    <Header />
    <main>
      <div bp="container">{children}</div>
      {hasNewsletter && <Newsletter />}
    </main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hasNewsletter: PropTypes.bool,
}

Layout.defaultProps = {
  hasNewsletter: true,
}

export default Layout
