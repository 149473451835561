import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TestimonialCard from "../components/TestimonialCard"

const Home = () => (
  <Layout>
    <Seo title="Home" />

    <div bp="grid 12 6@sm">
      <div>
        <p className="text-display">Get battle tested knowledge</p>

        <p>
          We teach the lessons we have learnt the hard way in production. If you
          are looking for knowledge distilled from production experience, you
          are at the right place.
        </p>

        <p>
          Pick your topics: <Link to="/workshops/tags/React/">React</Link>,{" "}
          <Link to="/workshops/tags/Docker/">Docker</Link>,{" "}
          <Link to="/workshops/tags/Terraform/">Terraform</Link>
        </p>

        <p className="cta-container">
          <Link className="button button-cta lead-cta" to="/workshops/">
            Check our next workshops
          </Link>
        </p>
      </div>
      <div bp="hide show@md" className="testimonial-card-list">
        <TestimonialCard
          testimonial="I gained deep knowledge of the most contemporary software architecture methods. The material was adequate and the instructors well prepared and experts in this domain!"
          person="Matina Tsavli, Security Architect at Nokia"
        />
        <TestimonialCard
          testimonial="Very hands-on workshop. The instructors are very passionate about
            the subject and they make the whole experience funny and
            educational!"
          person="Michael Petychakis, CTO at Orfium"
        />
      </div>
    </div>
  </Layout>
)

export default Home
