import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const Home = () => (
  <Layout hasNewsletter={false}>
    <Seo title="Thank you" />

    <h1 className="text-display text-center">Thanks!</h1>

    <p className="text-center">
      You subscribed successfully to our mailing list.
    </p>

    <p className="text-center">
      We will keep you up to date with new workshops we announce{" "}
      <strong>once per month</strong>.
    </p>

    <p className="text-center">
      If you get tired of it, you can opt out at any time.
    </p>

    <p className="text-center">
      <Link className="button button-cta lead-cta" to="/workshops/">
        Check our next workshops
      </Link>
    </p>
  </Layout>
)

export default Home
