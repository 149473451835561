import React from "react"
import { graphql } from "gatsby"
import moment from "moment"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Arrows from "components/Arrows"
import {
  getAvailability,
  getGeneralAvailabilityTicket,
  getEarlyBirdTicket,
  getJsonLd,
  getMeta,
} from "../../utils/workshops"

import "../../styles/workshop.scss"

export default ({ data, pageContext }) => {
  const today = new Date()
  const { markdownRemark } = data
  const workshop = markdownRemark.frontmatter
  const {
    title,
    excerpt,
    date,
    start,
    end,
    venue_name,
    venue_url,
    instructor_name,
    instructor_url,
    eventbrite_link,
    tickets,
  } = workshop
  const availability = getAvailability(tickets)
  const generalAvailabilityTicket = getGeneralAvailabilityTicket(tickets)
  const earlyBirdTicket = getEarlyBirdTicket(tickets)
  const html = markdownRemark.html
  const meta = getMeta(workshop)
  const grabYourTicket =
    moment(date).isAfter(today) && availability !== "soldout" ? (
      <a
        className="button button-cta tickets-link"
        href={eventbrite_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fas fa-ticket-alt" /> Grab your ticket
      </a>
    ) : null

  return (
    <Layout>
      <Seo title={title} description={excerpt} meta={meta} />

      <div className="workshop-details-container">
        <div
          className={`workshop-details-heading availability-${availability.toLowerCase()}`}
        >
          <h1 className="workshop-title">{title}</h1>
          {grabYourTicket}
        </div>

        <ul
          className={`workshop-details ${earlyBirdTicket ? "early-bird" : ""}`}
        >
          <li>
            <strong>When</strong> {moment(date).format("dddd D MMMM YYYY")}{" "}
            {moment(start).format("HH:mm")} - {moment(end).format("HH:mm")}
          </li>
          <li>
            <strong>Where</strong>{" "}
            <a href={venue_url} target="_blank" rel="noopener noreferrer">
              {venue_name}
            </a>
          </li>
          <li>
            <strong>Who</strong>{" "}
            <a href={instructor_url} target="_blank" rel="noopener noreferrer">
              {instructor_name}
            </a>
          </li>
          <li className="workshop-price">
            <strong>{earlyBirdTicket ? "Early Bird Price" : "Price"}</strong>
            {earlyBirdTicket ? (
              <>
                <span className="strikethrough-price">
                  €{generalAvailabilityTicket.price}
                </span>
                <span className="current-price">€{earlyBirdTicket.price}</span>
              </>
            ) : (
              `€${generalAvailabilityTicket.price}`
            )}
          </li>
        </ul>

        <div dangerouslySetInnerHTML={{ __html: html }} />

        <Arrows prev={pageContext.prev} next={pageContext.next} />
      </div>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(getJsonLd(workshop)),
        }}
      />
    </Layout>
  )
}

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        path
        title
        excerpt
        date
        start
        end
        tickets {
          name
          price
          availability
          from
          to
        }
        instructor_name
        instructor_url
        venue_name
        venue_url
        availability
        image
        eventbrite_link
      }
    }
  }
`
