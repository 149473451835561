import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const StyledArrows = styled.div`
  align-items: center;
  display: flex;
  justify-content: ${props => {
    if (props.prev === null) {
      return "flex-end"
    }

    if (props.next === null) {
      return "flex-start"
    }

    return "space-between"
  }};
`

const StyledLink = styled(Link)`
  font-size: 0.8em;
  text-decoration: none;
`

const Arrows = ({ prev, next }) => (
  <StyledArrows prev={prev} next={next}>
    {prev && (
      <StyledLink to={prev.frontmatter.path}>
        &laquo; {prev.frontmatter.title}
      </StyledLink>
    )}
    {next && (
      <StyledLink to={next.frontmatter.path}>
        {next.frontmatter.title} &raquo;
      </StyledLink>
    )}
  </StyledArrows>
)

Arrows.defaultProps = {
  next: null,
  prev: null,
}

Arrows.propTypes = {
  next: PropTypes.shape({
    frontmatter: PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
      date: PropTypes.string,
    }),
  }),
  prev: PropTypes.shape({
    frontmatter: PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
      date: PropTypes.string,
    }),
  }),
}

export default Arrows
