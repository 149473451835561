import React from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import WorkshopCard from "components/WorkshopCard"

export const getWorkshopCardsForEdges = edges =>
  edges.map(edge => {
    const { frontmatter } = edge.node
    return <WorkshopCard key={frontmatter.path} workshop={frontmatter} />
  })

export default ({ title, edges }) => (
  <div className="workshop-grid-container">
    <h1 className="workshop-grid-title">{title}</h1>
    <ReactCSSTransitionGroup
      bp="grid 12 12@md 6@lg 4@xl"
      transitionName="animated-item"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnter={true}
      transitionEnterTimeout={500}
      transitionLeave={false}
    >
      {getWorkshopCardsForEdges(edges)}
    </ReactCSSTransitionGroup>
  </div>
)
